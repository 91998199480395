import React from "react";
import {
  DEVICE_COMMAND_RESPONSE_STATUS,
  DEVICE_COMMAND_STATUS,
  MessageType,
} from "../../../../../Constant";
import {
  Paginator,
  Search as SearchBar,
  DatePicker,
  SelectFilter,
} from "../../../../../shared/widgets";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { AuthenticationService } from "../../../../../services/authorization";
import { fetchDeviceConfig,fetchDeviceResponseFromCommandId } from "../../services/reportService";
import { Modal, ButtonToolbar } from "react-bootstrap";
import * as moment from "moment";
import { DeviceConfigurationConfig } from "../../utils";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/";
import { connect } from "react-redux";
import { EpochtoEpochTZ } from "../../../../../shared/utils";
import { CreateDeviceConfig } from "./CreateDeviceConfig";
import { getRegionsForPanicReport } from "../../../../../services/vehicleMasterService";
import "./index.css";
import uuid from "uuid";
import { RegionSelector } from "../../../../../shared/components";
import { routes } from "../../../../../routing/routes";
import { userAuthoritiesArray} from "../../../../../services/authorization/Authorization";

export class DeviceConfiguration extends React.Component {
  constructor(props) {
    super(props);
    let m = moment(new Date());
    this.authService = AuthenticationService.getInstance();
    this.state = {
      pageNo: 0,
      totalPages: 0,
      totalItems: 0,
      filteredData: this.props.data,
      data: this.props.data,
      showSelectModal: false,
      showDetailModal: false,
      showCommandModal:false,
      RSUdeviceId: null,
      dataCCO: null,
      loadingstatus: null,
      searchString: "",
      placeHolder: "Search",
      sortOrder: "DESC",
      startDate: "",
      endDate: "",
      selectedDate: m.subtract(0, "day"),
      commandStatus: "",
      commandResponseStatus: "",
      backdrop:false,
      tableHeadings: [
        "Command Response",
        "Command Response Arrived At"
      ],
    };
  }

  componentDidMount() {   
    const role = userAuthoritiesArray();
    if(role.toString() != 'Admin'){
      this.props.history.push({pathname:'/'});
    }
  }

  handleRegionChange = (region) => {
    let startDate = parseFloat(
      this.state.selectedDate.startOf("day").format("x")
    );
    let endDate =
      parseFloat(this.state.selectedDate.endOf("day").format("x")) + 1;
    this.setState({ selectedRegion: region,startDate,endDate,pageNo:0 }, () => {
      this.fetchDeviceConfigurations();
    });
  };

  getDeviceRegionCommandAndCommandSetCol = (data) => {
    return (
      <>
        <td className="list-item" title={data.deviceId || "NA"}>
          {data.deviceId || "NA"}
        </td>
        <td className="list-item" title={data.command || "NA"}>
          {data.command || "NA"}
        </td>
        <td
          className="list-item"
          title={
            data.commandSentAt
              ? moment(
                  EpochtoEpochTZ(
                    data.commandSentAt,
                    localStorage.getItem("timeZone")
                  )
                ).format("DD MMM YY h:mm:ss A")
              : "NA"
          }
        >
          {data.commandSentAt
            ? moment(
                EpochtoEpochTZ(
                  data.commandSentAt,
                  localStorage.getItem("timeZone")
                )
              ).format("DD MMM YY h:mm:ss A")
            : "NA"}
        </td>
        <td className="list-item" title={data.commandStatus || "NA"}>
          {data.commandStatus || "NA"}
        </td>
      </>
    );
  };

  handleRefresh = () => {
    this.setState({ searchString: "", pageNo: 0,commandStatus: "",commandResponseStatus: "" }, () => {
      this.fetchDeviceConfigurations();
    });
  }

  getFilters = ()=>{
    return (
      <div className="header-button-container">
      <div className="form-group">
        <p className="filters-label">Filter By:</p>
        <div
          className="dataTables_length wrapper-gps"
          id="dataTables-example_length"
        >
          <ButtonToolbar>
            <RegionSelector
              filterKey={routes.REPORT}
              onRegionChange={this.handleRegionChange}
            />
            <SelectFilter
              value={this.state.commandStatus}
              onChange={(value) => {
                if (!value) {
                  value = {
                    key: "",
                    value: "",
                  };
                }
                this.setState({ commandStatus: value.key,pageNo:0 }, () => {
                  this.fetchDeviceConfigurations();
                });
              }}
              clearable={true}
              valueKey="key"
              labelKey="key"
              placeholder="Command Status"
              options={DEVICE_COMMAND_STATUS}
            />
            <SelectFilter
              value={this.state.commandResponseStatus}
              onChange={(value) => {
                if (!value) {
                  value = {
                    key: "",
                    value: "",
                  };
                }
                this.setState(
                  { commandResponseStatus: value.key,pageNo:0 },
                  () => {
                    this.fetchDeviceConfigurations();
                  }
                );
              }}
              clearable={true}
              valueKey="key"
              labelKey="key"
              placeholder="Command Resp Status"
              options={DEVICE_COMMAND_RESPONSE_STATUS}
            />
          </ButtonToolbar>
        </div>
      </div>
    </div>
    )
  }

  render() {
    return (
      <div className="device-page">
        <PageWrapper.Header
          heading="Device Configuration"
          hideS={true}
          showButton={true}
          title="Device Configuration"
          onAdd={this.openSelectModal.bind(this, null)}
        >
          <div className="pull-right">
            <a
              onClick={this.handleRefresh}
            >
              <span className="span-icon-refresh refresh-icon" />
            </a>
          </div>

          <span className="cluster-ip">
            Device Count : {this.state.totalItems ? this.state.totalItems : "0"}
          </span>
          <div
            className="dataTables_length wrapper-gps"
            id="dataTables-example_length"
          >
            <ButtonToolbar>
              <DatePicker
                date={this.state.selectedDate}
                onDateChange={this.dateChange}
                withoutDefaultBorder={false}
                todaySelectable={true}
              />
            </ButtonToolbar>
          </div>
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
              {this.getFilters()}
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header
              columns={DeviceConfigurationConfig}
              onSortToggle={this.onSortChange}
            />
            <tbody>
              {this.state.data &&
                this.state.data.map((data) => {
                  return (
                    <tr key={uuid.v4()} className="gradeA odd" role="row">
                      {this.getDeviceRegionCommandAndCommandSetCol(data)}
                      <td
                        className="list-item"
                        title={data.commandResponseStatus || "NA"}
                      >
                        {data.commandResponseStatus || "NA"}
                      </td>
                      <td className="list-item"  onClick={() => this.openCommandModal(data)}>
                      <i
                              className="fa fa-eye protocol"
                              aria-hidden="true"
                            />
                            <span className="protocol-list">view status</span>
                     </td>
                      <td className="action-column">
                        <span className="table-icons view-icon">
                          <a onClick={() => this.openDeviceDetailModal(data)}>
                            <i
                              className="fa fa-eye protocol"
                              aria-hidden="true"
                            />
                            <span className="protocol-list">view status</span>
                          </a>
                        </span>
                      </td>
                    </tr>
                  );
                })}

              {(!this.state.data ||
                (this.state.data && this.state.data.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="6">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.data && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
          {this.displaySelectModal()}
          {this.state.showDetailModal && this.displayDetailModal()}
          {this.state.showCommandModal && this.displayCommandModal()}
        </PageWrapper.Body>
      </div>
    );
  }

  dateChange = (date) => {
    if (!date) {
      date = moment(new Date());
    }
    let startDate = parseInt(date.startOf("day").format("x"));
    let endDate = parseInt(date.endOf("day").format("x")) + 1;
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        selectedDate: date,
        pageNo:0
      },
      () => {
        this.fetchDeviceConfigurations();
      }
    );
  };

  getList = (pageNo) => {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.setState({ pageNo }, () => {
        this.fetchDeviceConfigurations();
      });
    }
  };

  handleSearchChange = (key) => {
    this.setState(
      {
        searchString: key,
      },
      () => {
        this.fetchDeviceConfigurations();
      }
    );
  };

  openSelectModal = () => {
    this.setState({
      invalid: true,
      showSelectModal: true,
    });
  };
  onSortChange = (sort, key) => {
    this.setState(
      {
        sortOrder: sort,
        pageNo: 0,
      },
      () => {
        this.fetchDeviceConfigurations();
      }
    );
  };
  openDeviceDetailModal = (data) => {
    this.setState({
      showDetailModal: true,
      selectedItem: data,
    });
  };
openCommandModal=(data)=>{
  this.props.updateLoader(true);
  fetchDeviceResponseFromCommandId(data.id)
  .then((response) => {
    this.props.updateLoader(false);
    this.setState({
      showCommandModal:true,
      selectedCommand:response
    })
  })
  .catch(error=>{
    this.props.updateLoader(false);
    this.props.initiateNotification({
      type: MessageType.ERROR,
      message:
        error.description ||
        "Error occurred  while getting data for device response",
    });
  })
}
  afterDeviceAddition = () => {
    this.setState({ showSelectModal: false, invalidJson: false }, () => {
      this.props.initiateNotification({
        type: MessageType.SUCCESS,
        message: "Device added successfully",
      });
      this.fetchDeviceConfigurations();
    });
  };

  displaySelectModal = () => {
    return (
      <Modal
        show={this.state.showSelectModal}
        onHide={() => {
          this.setState({ showSelectModal: false, invalidJson: false });
          this.formData = null;
        }}
        backdrop={this.state.backdrop}

      >
        <Modal.Header closeButton>
          <Modal.Title>Create Device Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateDeviceConfig
            onClose={() => {
              this.setState({ showSelectModal: false, invalidJson: false });
            }}
            onComplete={this.afterDeviceAddition}
            initiateNotification={this.props.initiateNotification}
            selectedRegion={this.state.selectedRegion}
          />
        </Modal.Body>
      </Modal>
    );
  };
  displayDetailModal = () => {
    const {
      deviceId,
      regionId,
      command,
      commandSentAt,
      commandStatus,
      commandResponseStatus,
      commandResponseArrivedAt,
      createdAt,
      createdBy,
    } = this.state.selectedItem;
    const getCommand = ()=>{
      if(!command) return 'NA';
      return command.replace(/-/g, '\u2011')
    }
    return (
      <Modal
        show={this.state.showDetailModal}
        onHide={() => {
          this.setState({ showDetailModal: false, selectedItem: null });
        }}
        dialogClassName="info-modal"
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Device Configuration Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row device-info device-configuration">
            <div className="col-sm-12">
              <div className="row">
                <span className="info-value">Device Id -&nbsp;</span>
                <span className="info-label">{deviceId || "NA"}</span>
              </div>

              <div className="row">
                <span className="info-value">Region Id -&nbsp;</span>
                <span className="info-label">{regionId || "NA"}</span>
              </div>

              <div className="row">
                <span className="info-value">Command Sent At -&nbsp;</span>
                <span className="info-label">
                  {(commandSentAt &&
                    moment(
                      EpochtoEpochTZ(
                        commandSentAt,
                        localStorage.getItem("timeZone")
                      )
                    ).format("DD MMM YY h:mm:ss A")) ||
                    "NA"}
                </span>
              </div>

              <div className="row">
                <span className="info-value">Command Status -&nbsp;</span>
                <span className="info-label">{commandStatus || "NA"}</span>
              </div>

              <div className="row">
                <span className="info-value">
                  Command Response Status -&nbsp;
                </span>
                <span className="info-label">
                  {commandResponseStatus || "NA"}
                </span>
              </div>

              <div className="row">
                <span className="info-value">
                  Command Response Arrived At -&nbsp;
                </span>
                <span className="info-label">
                  {(commandResponseArrivedAt &&
                    moment(
                      EpochtoEpochTZ(
                        commandResponseArrivedAt,
                        localStorage.getItem("timeZone")
                      )
                    ).format("DD MMM YY h:mm:ss A")) ||
                    "NA"}
                </span>
              </div>

              <div className="row">
                <span className="info-value">Created At -&nbsp;</span>
                <span className="info-label">
                  {moment(
                    EpochtoEpochTZ(createdAt, localStorage.getItem("timeZone"))
                  ).format("DD MMM YY h:mm:ss A") || "NA"}
                </span>
              </div>
              <div className="row">
                <span className="info-value">Created By -&nbsp;</span>
                <span className="info-label">{createdBy || "NA"}</span>
              </div>
            <div className="command-row">
                <div className="command-col-1">Command - </div>
                <div className="command-col-2">{getCommand()}</div>
            </div>
            
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  displayCommandModal=()=>{
    const commandResponse = this.state.selectedCommand;
    return (
      <Modal           
      show={this.state.showCommandModal}
      onHide={() => {
        this.setState({ showCommandModal: false, selectedCommand: null });
      }}
      dialogClassName="info-modal"
      backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Command Responses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="create-command-table">
        <div className="table-heading table-header">
          {this.state.tableHeadings.map((tableHeading) => {
              return <span key={tableHeading}>{tableHeading}</span>;
            })}
        </div>
        <div className="scroll-container-1 cmd-scroll">
          {
            commandResponse && commandResponse.map((data,index)=>{
              return(
                <div key={index} className="table-heading table-row">
                  <p className="selected-command-name">{data.commandResponse || 'NA'}</p>
                  <p className="selected-command-name">{data.commandResponseArrivedAt
                          ? moment(
                              EpochtoEpochTZ(
                                data.commandResponseArrivedAt,
                                localStorage.getItem("timeZone")
                              )
                            ).format("DD MMM YY h:mm:ss A")
                          : "NA"}</p>
                  </div>
              )
            })
          }
        </div>
        </div>
        </Modal.Body>
      </Modal>
    )
  }
  fetchAddUpdate = () => {
    this.createCCO(this.state.RSUdeviceId);
    this.setState({ showSelectModal: false });
  };

  fetchDeviceConfigurations = () => {
    this.props.updateLoader(true);
    fetchDeviceConfig(this.state)
      .then((data) => {
        this.setState({
          data: data.items,
          totalPages: data.totalPages,
          pageNo: data.currentPage,
          totalItems: data.totalItems,
        });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.setState({
          data: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
        });
        this.props.updateLoader(false);
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
        this.props.initiateNotification({
          type: MessageType.ERROR,
          message:
            err.description ||
            "Error occurred  while getting data for device configurations",
        });
      });
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleError = (err) => {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.initiateNotification({
        type: MessageType.ERROR,
        message: "Invalid token",
      });
    } else if (this.mounted) {
      this.props.initiateNotification({
        type: MessageType.ERROR,
        message:
          err.description || "Error occurred in while getting data for regions",
      });
    }
  };

  fetchRegions = () => {
    getRegionsForPanicReport()
      .then((res) => {
        if (res && res.items) {
          const orderedNewOptions = res.items.sort((a, b) => {
            if (a.regionId > b.regionId) {
              return 1;
            } else if (b.regionId > a.regionId) {
              return -1;
            } else {
              return 0;
            }
          });

          const regionOptions = orderedNewOptions.map((data) => {
            return {
              label: this.capitalize(data.regionId),
              value: data.regionId,
            };
          });
          this.setState(
            {
              regionOptions,
              selectedRegion: regionOptions[0],
            },
            () => {
              this.dateChange();
            }
          );
        } else {
          this.setState({ regionOptions: [] });
        }
      })
      .catch((err) => {
        this.handleError(err);
      });
  };
}

export default connect(null, defaultMapDispatchToProps)(DeviceConfiguration);

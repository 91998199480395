import React from "react";
import { connect } from "react-redux";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { templateStrings, MessageType } from "../../../../../Constant";
import { Modal, ButtonToolbar } from "react-bootstrap";
import { fetchVehicleMaster, upadteMasterDataStatus ,upadteMasterDataLiveTracking,fetchCountryData,fetchCameraData,downLoadCameraData,deleteCameraMasterData,fetchAllBusClassification,fetchServiceProvider, getAllLanguages,filterLanguageMasterReport} from "../../../../../services/vehicleMasterService";
import * as moment from "moment";
import { CityCreate } from '../createView/createCityMaster';
import { AgencyCreate } from '../createView/createAgency';
import { DepotCreate } from '../createView/createDepot';
import { CountryCreate } from "../createView/createCountry";
import OperatorCreate from '../createView/createOperator';
import {CameraCreate} from '../createView/createCamera'
import { ServiceCategoryCreate } from '../createView/createServiceCategory';
import { AuthenticationService, OPERATIONS, getUserAuthorities } from "../../../../../services/authorization";
import { Paginator, Search as SearchBar, FilterView,DropdownWrapper } from "../../../../../shared/widgets";
import { ConfirmationModal } from '../../../../../shared/components'
import {
    masterType, cityMasterTemplateStrings, agencyMasterTemplateStrings,
    operatorMasterTemplateStrings, depotMasterTemplateStrings, masterModalConfig, countryMasterTemplateStrings,cameraHeadings,
    ServiceCategoryNameHeading,ServiceProviderNameHeading,LanguageMasterTemplateStrings
} from '../../utils'
import { routes } from '../../../../../routing/routes'
import _ from 'lodash';
import { ToggleView } from '../../../../../shared/widgets'
import { VehicleMasterView } from '../detailView/vehicleMasterView';
import {SelectFilter} from '../../../../../shared/widgets/selectFilter/selectFilter';
import {ImportButton } from "../../../../../shared/components";
import {EpochtoEpochTZ}  from '../../../../../shared/utils';
import './masterlist.css'
import { CreateLanguageWithCity } from "../createView/createLanguageWithCity";

class MasterList extends React.Component {
    constructor(props) {
        super(props);
        this.authService = AuthenticationService.getInstance();
        let m = moment(new Date());
        this.state = {
            type: this.props.match.params.masterType,
            showHeaderButton: true,
            pageNo: 0,
            totalPages: 0,
            isUserAViewer: false,
            date: m,
            todaySelectable: true,
            backdrop: false,
            selectedCountry: {id:1, name:'INDIA'},
            exportOptions: [
                {
                  key: "data",
                  value: "Export Data ",
                },
                {
                  key: "format",
                  value: "Export Format",
                },
              ],
            showStatusChangeConfirmModal: false,
            exportButtonLoader: false,
            showDeleteModal:false,
            activeTab : 'category',
            filteredLanguages:[]
        };
        this.supportedTypes = [".xlsx"];
        this.getList = this.getList.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.exportCameraFormat = this.exportCameraFormat.bind(this);
        this.fetchBusClassificationData = this.fetchBusClassificationData.bind(this);
        this.getTableHeading = this.getTableHeading.bind(this);
        this.tabFilter = [
          {
            key: "category",
            value: "Service Category",
          },
          {
            key: "provider",
            value: "Service Provider",
          },
        ];
        this.isServiceCategory = this.isServiceCategory.bind(this);
        this.fetchServiceProviderData = this.fetchServiceProviderData.bind(this);
        this.deleteCameraRecord = this.deleteCameraRecord.bind(this);
        this.fetchCameraData = this.fetchCameraData.bind(this);
        this.openLanguageModal = this.openLanguageModal.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        switch(this.state.type) {
          case masterType.camera:
            this.fetchCameraData();
            this.fetchCityMasterData();
            break;
          case masterType.serviceCategory:
            this.fetchBusClassificationData();
            break;
          case masterType.city:
            this.fetchCountryData();
            this.fetchMasterData(this.state.pageNo, this.state.selectedCountry);
            break;
          case masterType.language:
            this.fetchCountryData();
            this.fetchCityMasterData();
            this.getLanguageOptions();
            this.fetchMasterData(this.state.pageNo, this.state.selectedCountry);
            break;
          case masterType.depot:
          case masterType.agency:
            this.fetchCityMasterData();
            break;
          default:
            this.fetchMasterData(this.state.pageNo, this.state.selectedCountry);
            break;
        }
        this.updateRightsForRegion(this.state.selectedRegion);
    }

    updateRightsForRegion(region) {
        let authorities = JSON.parse(getUserAuthorities())
        let authoritiesArray = Object.keys(authorities);

        this.setState({
            canWrite: true
        });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.masterType != this.state.type) {
            this.setState({
                type: nextProps.match.params.masterType,
                content: [],
                pageNo: 0,
                selectedCity: null,
                cityId: null,
                searchString: ''
            }, () => {
              if(this.state.type != masterType.serviceCategory){
                this.fetchMasterData(this.state.pageNo,this.state.selectedCountry);
              }
              if(this.state.type == masterType.serviceCategory){
                this.fetchBusClassificationData();
              }
                if(this.state.type === masterType.city){ this.fetchCountryData()}
                if (this.state.type === masterType.depot) { this.fetchCityMasterData() }

            })
        }
    }

    fetchCameraData(){
        fetchCameraData(this.state.searchString,this.state.pageNo)
        .then((res => {
            if (this.mounted) {
                this.setState({ 
                    content: res.items, 
                    totalPages: res.totalPages,
                    pageNo: res.currentPage 
                }, () => {
                });
            }
        })).catch(err => {
            this.errorHandler(err)
        });
    }

    exportCameraFormat(format){
        downLoadCameraData(format)
        .then(this.setState({ exportButtonLoader: true }))
        .then((res) => {
          this.setState({ exportButtonLoader: false });
        })
        .catch((err) => {
          this.setState({ exportButtonLoader: true });
          if (err.status === 401 || err.message === "Invalid token") {
            console.log("unauthorized", err.description);
            this.props.setTokenExpired(true);
          }
        });
    }

    fetchData = (fetchFunction) => {
      this.props.updateLoader(true);
      fetchFunction(this.state)
        .then((res) => {
          this.props.updateLoader(false);
          let updateSelection = null;
          if(this.state.updateSelection){
            if(this.isServiceCategory()){
              updateSelection = res.items.find((item)=>item.serviceId == this.state.updateSelection.serviceId);
            }else{
              updateSelection = res.items.find((item)=>item.serviceProviderId == this.state.updateSelection.serviceProviderId);
            }
          }
          const data = {
            content: res.items,
            totalPages: res.totalPages,
            pageNo: res.currentPage,
            updateSelection
          }
          if(!updateSelection){
            data['showModal']=false;
            data['showInfo']=false;
          }
          this.setState(data);
        })
        .catch((err) => {
          const data = {
            showModal:false,
            showInfo:false,
          }
          this.setState(data);
          this.props.updateLoader(false);
          this.errorHandler(err);
        });
    }
    
    fetchServiceProviderData() {
      this.fetchData(fetchServiceProvider);
    }
    
    fetchBusClassificationData() {
      this.fetchData(fetchAllBusClassification);
    }
    
    isServiceCategory(){
      return this.state.activeTab == 'category';
    }

    getTableHeading(){
      let tableHeading = {};
      switch(this.state.type){
        case masterType.city:
          tableHeading = cityMasterTemplateStrings;
          break;
        case masterType.agency:
          tableHeading = agencyMasterTemplateStrings;
          break;
        case masterType.operator:
          tableHeading = operatorMasterTemplateStrings;
          break;
        case masterType.depot:
          tableHeading = depotMasterTemplateStrings;
          break;
        case masterType.country:
          tableHeading = countryMasterTemplateStrings;
          break;
        case masterType.camera:
          tableHeading = cameraHeadings;
          break;
        case masterType.serviceCategory:
          if(this.isServiceCategory()){
            tableHeading = ServiceCategoryNameHeading;
          }else{
            tableHeading = ServiceProviderNameHeading;
          }
          break;
        case masterType.language:
          tableHeading = LanguageMasterTemplateStrings;
          break;
        default:
          tableHeading = {};
      }
      return tableHeading;
    }

    handleTabChange(value) {
      this.setState({ activeTab: value,pageNo:0 }, () => {
        if(value == 'category'){
          this.fetchBusClassificationData();
        }else{
          this.fetchServiceProviderData();
        }
      });
    }

    getTabForServiceCategory = ()=>{
      return (
        <div className="tab-container tab-container-service-category">
        <div className="inner-tab">
          <ul className="sub-tab">
            {this.tabFilter.map((tab) => {
              return (
                <li
                  key={tab.key}
                  onClick={() => {
                    if (this.state.activeTab !== tab.key) {
                      this.handleTabChange(tab.key);
                    }
                  }}
                  className={
                    this.state.activeTab === tab.key ? "activeTab" : ""
                  }
                >
                  {tab.value}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      )
    }

    getSearch = ()=>{
      return (
        <div className="pull-right search-container custom-search">
                <SearchBar
                  placeHolder="search"
                  onSearchChange={this.handleSearchChange}
                  value={this.state.searchString}
                />
        </div>
      )
    }

    getCountryTemplate = ()=>{
      return (
        <ButtonToolbar>
          <SelectFilter
            value={this.state.selectedCountry}
            onChange={(value) => {
              this.onFilterChangeCountry(value)
            }}
            valueKey="name"
            labelKey="name"
            placeholder="Country"
            options={this.state.countries}
          />
      </ButtonToolbar>
      )
    }

    getCityTemplate = ()=>{
      return (
        <ButtonToolbar>
          <SelectFilter
            value={this.state.selectedCity}
            onChange={(value) => {
              this.onFilterChange(value, "city");
            }}
            clearable={true}
            valueKey="name"
            labelKey="name"
            placeholder="City"
            options={this.state.cities}
          />
        </ButtonToolbar>
      )
    }

    getLanguageFilterTemplate =()=>{
      return (
        <ButtonToolbar>
          <SelectFilter
            value={this.state.selectedLanguage}
            onChange={(value) => {
              this.onFilterChange(value, "language");
            }}
            clearable={true}
            valueKey="languageId"
            labelKey="language"
            placeholder="Language"
            options={this.state.allLanguages}
          />
        </ButtonToolbar>
      )
    }

    getLanguageOptions = ()=>{
      getAllLanguages()
      .then((response)=>{
        this.setState({allLanguages:response,filteredLanguages:response});
      })
      .catch(()=>{
        this.props.initiateNotification({
          type:MessageType.ERROR,
          msg:'Error while fetching languages'
        })
      })
    }

    openLanguageModal(){
        this.setState({showLanguageModal:true});
    }

    getPageHeader = ()=>{
      let header = _.startCase(_.toLower(this.state.type));
      if(this.state.type == masterType.serviceCategory){
        if(this.state.activeTab == 'category'){
          header = 'Service Category';
        }else{
          header = 'Service Provider';
        }
      }
      return header;
    }

    getImportButtonTemplate = ()=>{
      return <ImportButton
                    importKey="camera"
                    regionId={this.state.selectedRegion}
                    supportedTypes={this.supportedTypes}
                    onMessage={(e, msg) => {
                      if (e == MessageType.SUCCESS) {
                        this.props.initiateNotification({
                          type: e,
                          message: msg,
                        });
                        this.fetchCameraData();
                      } else if (
                        e == MessageType.ERROR &&
                        msg === "Invalid token"
                      ) {
                        this.props.setTokenExpired(true);
                      } else {
                        this.props.initiateNotification({
                          type: e,
                          message: msg,
                        });
                      }
                    }}
         />
    }

    render() {
        const templateStrings = this.getTableHeading();

        let pageHeader =this.getPageHeader();
        return (
          <div>
            {this.state.type == masterType.serviceCategory && this.getTabForServiceCategory()}
            <PageWrapper.Header
              heading={pageHeader}
              onAdd={this.open.bind(this, null)}
              showButton={this.state.showHeaderButton && this.state.canWrite}
              hideS={true}
            >
              <div className="pull-right " style={{ marginRight: "10px" }}>
                {this.state.type === masterType.camera ? (
                  this.getImportButtonTemplate()
                ) : (
                  ""
                )}
              </div>
              <div className="pull-right">
                {this.state.type === masterType.camera ? (
                  <DropdownWrapper
                    title={
                      this.state.exportButtonLoader ? (
                        <>
                          <i className="fa fa-spinner fa-spin"></i>
                          <span>{""}</span>
                          <span>Exporting...</span>
                        </>
                      ) : (
                        "Export"
                      )
                    }
                    disabled={this.state.exportButtonLoader}
                    menue={this.state.exportOptions}
                    onSelect={(value, subValue) => {
                      switch (value) {
                        case "data": {
                          this.exportCameraFormat();
                          break;
                        }
                        case "format": {
                          this.exportCameraFormat("format");
                          break;
                        }
                      }
                    }}
                  ></DropdownWrapper>
                ) : (
                  ""
                )}
              { this.state.type == masterType.language && (
                <button className="language-btn" onClick={this.openLanguageModal}>Show Languages</button>
              )}
              </div>
              { this.state.type != masterType.serviceCategory && this.state.type != masterType.language && this.getSearch()}
            </PageWrapper.Header>
            <PageWrapper.Body>
              {(this.state.type === masterType.depot ||
                this.state.type === masterType.agency || this.state.type===masterType.camera) && (
                <PageWrapper.BodyHeader>
                  <div className="header-button-container">
                    <div className="form-group">
                      <p className="filters-label">Filter By:</p>
                      <div
                        className="dataTables_length wrapper-gps"
                        id="dataTables-example_length"
                      >
                        {this.getCityTemplate()}
                      </div>
                    </div>
                  </div>
                </PageWrapper.BodyHeader>
              )}
              {(this.state.type === masterType.city ||
                this.state.type === masterType.language)
              && (
                <PageWrapper.BodyHeader>
                  <div className="header-button-container">
                    <div className="form-group">
                      <p className="filters-label">Filter By:</p>
                      <div
                        className="dataTables_length wrapper-gps"
                        id="dataTables-example_length"
                      >
                        {this.getCountryTemplate()}
                      </div>
                      {this.state.type === masterType.language && <div
                        className="dataTables_length wrapper-gps"
                        id="dataTables-example_length"
                      >
                        {this.getCityTemplate()}
                      </div>}
                      {this.state.type === masterType.language && <div
                        className="dataTables_length wrapper-gps"
                        id="dataTables-example_length"
                      >
                        {this.getLanguageFilterTemplate()}
                      </div>}
                    </div>
                  </div>
                </PageWrapper.BodyHeader>
              )}
              <TableWrapper>
                <TableWrapper.Header columns={templateStrings} />
                {
                  <tbody>
                    {this.state.content &&
                      this.state.content.length > 0 &&
                      this.state.content.map((data) => {
                        return this.displayList(data, templateStrings);
                      })}
                    {(!this.state.content ||
                      (this.state.content &&
                        this.state.content.length === 0)) && (
                      <tr className="gradeA odd noDataStyle" role="row">
                        <td className="sorting_1" colSpan="6">
                          No Data Found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                }
              </TableWrapper>
              <Paginator
                pageNo={this.state.pageNo}
                totalPages={this.state.totalPages}
                getList={this.getList}
              />
              {this.displayModal()}
              {this.state.showUpdateConfirmationModal &&
                this.displayConfirmationModal("status")}
              {this.state.showUpdateliveTrackingModal &&
                this.displayConfirmationModal("liveTracking")}
              {this.state.showDeleteConfirmationModal &&
                this.displayConfirmationModal("delete")}
              {this.state.showLanguageModal && this.displayLanguageModal()}
            </PageWrapper.Body>
          </div>
        );
    }

    getLanguageUpsertState = (res)=> {
      let languageUpsertState = {};
      if(this.state.type == masterType.language && this.state.updateSelection){
        languageUpsertState['updateSelection'] = res.items.find((el)=>el.cityId == this.state.updateSelection.cityId);
      }
      return languageUpsertState;
    }
    /**
     * fetches master data
     */
    fetchMasterData(pageNo,country) {
        if(this.mounted)  this.props.updateLoader(true);
        const masterTypeKey = this.state.cityId ? {
            cityId: this.state.cityId
        } : null
        fetchVehicleMaster(this.state.type, masterTypeKey, pageNo, 10, this.state.searchString,null,country.id,country.name).then((res => {
            if (this.mounted) {
                if(this.state.type === masterType.country){
                    this.setState({
                        content: res.items,
                        totalPages: res.totalPages,
                        pageNo: res.currentPage
                    })
                }
                else{
                  if(this.state.type == masterType.camera || this.state.type == masterType.language){
                    this.setState({
                      content: res.items,
                      totalPages: res.totalPages,
                      pageNo: res.currentPage,
                      ...this.getLanguageUpsertState(res)
                   })
                  }else{
                    this.setState({
                        content: res.content,
                        totalPages: res.totalPages,
                        pageNo: res.number
                    })
                  }
                }
                this.props.updateLoader(false);
            }

        })).catch(err => {
            this.errorHandler(err)
        });
    }


    onFilterChange(value,type) {
      const data = {
        pageNo: 0,
      };
      if(type == "city"){
        data['cityId'] = value && value.id ? value.id : null;
        data['selectedCity'] = value && value.name ? value.name : null
      }else if(type == "language"){
        data['selectedLanguage'] = value && value.languageId?value.languageId:null
      }
        this.setState({
           ...data
        }, () => {
          if(this.state.type == masterType.language){
            this.filterLanguageMaster();
          }else{
            this.fetchMasterData(this.state.pageNo,this.state.selectedCountry)
          }
        });
    }

    filterLanguageMaster =()=>{
      const data = {
        cityId: this.state.cityId,
        languageId: this.state.selectedLanguage,
        pageNo:this.state.pageNo,
        countryId:this.state.selectedCountry.id
      }
      filterLanguageMasterReport(data)
      .then((res)=>{
        this.setState({
          content: res.items,
          totalPages: res.totalPages,
          pageNo: res.currentPage
        })
      })
      .catch(err=>{
        this.errorHandler(err)
      })
    }

    onFilterChangeCountry(value){
        if(value){
            this.setState({
                selectedCountry: value,
                pageNo: 0
            }, () => {
                if(this.state.type == masterType.language){
                  this.filterLanguageMaster()
                }else{
                  this.fetchMasterData(this.state.pageNo,this.state.selectedCountry)
                }
            });
        }
    }

    fetchCityMasterData() {
        fetchVehicleMaster('city', null, 0, 1000).then((res => {
            if (this.mounted) {
                this.setState({ cities: res.content }, () => {
                });
            }
        })).catch(err => {
            this.errorHandler(err)
        });
    }

    fetchCountryData() {
        fetchCountryData().then((res => {
            let all_countries = [];
            res['items'].forEach((item) => {
                all_countries.push({id:item.id, name: item.name});
            });
            if (this.mounted) {
                this.setState({ countries: all_countries }, () => {
                });
            }
        })).catch(err => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ("Invalid token"));
            } else if (this.mounted) {
                this.setState({ countriesOptions: [] });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching countries"
                });
            }        
        });
    }

    handleSearchChange(e) {

        if(this.state.type == masterType.camera){
            if(this.mounted){
                this.setState({searchString:e,pageNo:0},()=>{
                    this.fetchCameraData(); 
                })
            }
        }else{
            if (e) {
                if (this.mounted)  this.setState({ searchString: e, pageNo: 0 }, () => { this.fetchMasterData(this.state.pageNo,this.state.selectedCountry) });
    
            } else {
                if (this.mounted)  this.setState({ searchString: '', pageNo: 0 }, () => { this.fetchMasterData(this.state.pageNo,this.state.selectedCountry) });
            }
        }
    }

    errorHandler(err) {
        
        if (err.status === 401 || err.message === 'Invalid token') {
            this.props.setTokenExpired(true);
        } else if (this.mounted) {
            this.props.updateLoader(false);
            this.setState({ content: [], totalPages: 0, pageNo: 0 });
            this.props.initiateNotification({
                type: 'error',
                message: err.description ? err.description : "Error while fetching master data"
            });
        }
    }


    /**
    * Call when Add/Update modal Open.
    */
    open(data) {
        this.setState({
          updateSelection: data,
          showModal: true
        });
    }

    /**
     * Used to show Modal for "Add new master" or "Update master"
     */
    
    getModalTitle = () => {
      const { showModal, showInfo, type, updateSelection } = this.state;
      const isServiceCategoryType = type === masterType.serviceCategory && !this.isServiceCategory();
      const modalTypeKey = isServiceCategoryType ? 'serviceProvider' : type;
      if (showModal) {
        const actionType = updateSelection ? 'updateModalTitle' : 'createModalTitle';
        return masterModalConfig[modalTypeKey][actionType];
      } else if (showInfo) {
        return masterModalConfig[modalTypeKey]['viewModalTitle'];
      }
      return '';
    }

    onVerifyFetchOpertor = ()=>{
      this.setState({
        pageNo: 0
        }, ()=>{
          this.props.updateLoader(true);
          const masterTypeKey = this.state.cityId ? { cityId: this.state.cityId } : null;
          const {selectedCountry:country} = this.state;
          fetchVehicleMaster(this.state.type, masterTypeKey, this.state.pageNo, 10, this.state.searchString,null,country.id,country.name)
          .then((res => {
            this.props.updateLoader(false);
            const updateSelection =res.content.filter((operator)=>operator.id === this.state.updateSelection.id)[0]
            this.setState({
              content: res.content,
              totalPages: res.totalPages,
              pageNo: res.number,
              updateSelection
          })
          }))
          .catch(err => {
            this.props.updateLoader(false);
            this.errorHandler(err)
          })
        });
    }

    
    renderModalContent = () => {
      const { showModal, showInfo, type, updateSelection, activeTab,selectedCountry } = this.state;

      const masterDataCreationProps = {
        onComplete: (e, msg) => { this.OnMasterDataCreation(e, msg) },
        updateSelection: updateSelection,
        onClose: this.close.bind(this),
        initiateNotification: this.props.initiateNotification,
        country: selectedCountry,
        onVerifyFetchOpertor:this.onVerifyFetchOpertor
      }


      if (showModal) {
        switch (type) {
          case masterType.city:
            return <CityCreate {...masterDataCreationProps} />;
          case masterType.country:
            return <CountryCreate {...masterDataCreationProps} />;
          case masterType.agency:
            return <AgencyCreate {...masterDataCreationProps} />;
          case masterType.depot:
            return <DepotCreate {...masterDataCreationProps} />;
          case masterType.operator:
            return <OperatorCreate {...masterDataCreationProps} />;
          case masterType.serviceCategory:
            return <ServiceCategoryCreate {...masterDataCreationProps} activeTab={activeTab} />;
          case masterType.camera:
            return <CameraCreate {...masterDataCreationProps} />
          case masterType.language:
            return <CreateLanguageWithCity {...masterDataCreationProps} />
          default:
            return null;
        }
      }

      if (showInfo) {
        return (
          <VehicleMasterView
            masterType={type}
            data={updateSelection}
            onClose={this.close.bind(this)}
            initiateNotification={this.props.initiateNotification}
            activeTab={activeTab}
            fetchServiceReportOnUpsertOfAgency={this.fetchServiceReportOnUpsertOfAgency.bind(this)}
            onComplete= {(e, msg) => { this.OnMasterDataCreation(e, msg) }}
            synLanguageTableAfterIndividualLangRemove={this.synLanguageTableAfterIndividualLangRemove.bind(this)}
          />
        )
      }
      return null;
    }

    displayModal = () => {
      return (
        <Modal
          show={this.state.showModal || this.state.showInfo}
          onHide={this.close.bind(this)}
          backdrop={this.state.backdrop}
          dialogClassName={this.state.type === masterType.operator ? "custom-operator-modal" : ''}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.getModalTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderModalContent()}
          </Modal.Body>
        </Modal>
      )
    }
  
    languageSearch = (e)=>{
      let searchString = e.target.value
        if(searchString){
           let tempData = this.state.allLanguages.filter((item)=>{
            return item.language.toLowerCase().includes(searchString.toLowerCase())
          });
          this.setState({filteredLanguages:tempData})
        }else{
           this.setState({filteredLanguages: this.state.allLanguages})
        }
    }

    displayLanguageModal(){
      return (
        <Modal
          show={this.state.showLanguageModal}
          onHide={this.close.bind(this)}
          backdrop={this.state.backdrop}
          dialogClassName={this.state.type === masterType.operator ? "custom-operator-modal" : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>All Languages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="language-search">
            <div className="search-field">
              <input type="text" placeholder="Search Language" onChange={this.languageSearch} />
            </div>
            <div className="language-list language-scroll-container">
              {this.state.filteredLanguages && this.state.filteredLanguages.map((item,index)=>{
                return (
                  <div key={item.language}>{index+1}. {item.language}</div>
                )
              })}
            </div>
            {this.state.filteredLanguages.length === 0 && <div className="no-data">No Language found</div>}
          </div>
        </Modal.Body>
      </Modal>
      )
    }

    displayConfirmationModal(column) {
        let msg = 'Are you sure you want to update?';
        if(column == 'delete'){
            msg = 'Are you sure you want to delete?'
        }
        let showModalFlag = false;
        switch(column){
            case 'status':
               showModalFlag = this.state.showUpdateConfirmationModal;
               break;
            case 'liveTracking':
                showModalFlag = this.state.showUpdateliveTrackingModal;
                break;
            case 'delete':
                showModalFlag = this.state.showDeleteConfirmationModal;
                break;
            default:
              console.log('In default case');
        }
        
        return (
            <ConfirmationModal title="Confirmation" message={msg}
                showModal={showModalFlag} messageCallBack={(type) => {
                    if (type === MessageType.SUCCESS) {
                    if(column==='status'){this.updateStatus();}
                    else if(column == 'liveTracking'){this.updateliveTrackingStatus()}
                    else if(column == 'delete'){
                        this.deleteCameraRecord();
                    }
                    }
                    this.setState({
                        showUpdateConfirmationModal: false,
                        showUpdateliveTrackingModal:false,
                        showDeleteConfirmationModal:false
                    })
                }} />
        )
    }

    deleteCameraRecord(){
        deleteCameraMasterData(this.state.updateSelection.cameraId)
        .then((response)=>{
            this.props.initiateNotification({
                type:MessageType.SUCCESS,
                message:"Record Delete Sucessfully"
            })
            this.fetchCameraData();
        }).catch(err => {
            this.errorHandler(err)
        });
    }

    updateStatus(){
        upadteMasterDataStatus(this.state.type,
            { id: this.state.UpdateMasterId, status: this.state.updatedStatus }).then((res => {
                if (this.mounted) this.setState({
                    content: this.state.content.map((obj) => {
                        if (obj.id === this.state.UpdateMasterId) {
                            obj['status'] = this.state.updatedStatus
                        }
                        return obj

                    })
                })
            })).catch(err => {
                this.errorHandler(err)
            });
    }

    updateliveTrackingStatus(){
        upadteMasterDataLiveTracking(this.state.type,
            { id: this.state.UpdateMasterId, status: this.state.updatedStatus }).then((res => {
                if (this.mounted) this.setState({
                    content: this.state.content.map((obj) => {
                        if (obj.id === this.state.UpdateMasterId) {
                            obj['liveTracking'] = this.state.updatedStatus
                        }
                        return obj

                    })
                })
            })).catch(err => {
                this.errorHandler(err)
            });
    }

    OnMasterDataCreation(e, msg) {
        if (e === MessageType.SUCCESS) {
            if(this.state.type == masterType.camera){
                this.setState({
                    pageNo : 0,
                },()=>{
                    this.fetchCameraData();
                })
            } else if(this.state.type == masterType.serviceCategory){
              if(this.isServiceCategory()){
                this.fetchBusClassificationData();
              }else{
                this.fetchServiceProviderData();
              }
            }else{
                this.setState({
                    pageNo: 0
                }, this.fetchMasterData(this.state.pageNo,this.state.selectedCountry));
            }
            this.props.initiateNotification({
                type: e,
                message: msg
            });
        } else if (e === MessageType.ERROR && msg === "Invalid token") {
            this.props.setTokenExpired(true)
        } else {
            this.props.initiateNotification({
                type: e,
                message: msg
            });
        }


        this.close();
    }

    /**
     * Call when Add/Update modal Close.
     */
    close() {
        this.setState({
            showLanguageModal:false,
            showModal: false,
            showInfo: false,
            updateSelection: null,
            value: []
        });
        if(this.state.type == masterType.serviceCategory){
          if(this.isServiceCategory()){
            this.fetchBusClassificationData();
          }else{
            this.fetchServiceProviderData();
          }
        }else if(this.state.type == masterType.language){
          this.setState({filteredLanguages: this.state.allLanguages})
        }
    }

    fetchServiceReportOnUpsertOfAgency(){
      if(this.state.type == masterType.serviceCategory){
        if(this.isServiceCategory()){
          this.fetchBusClassificationData();
        }else{
          this.fetchServiceProviderData();
        }
      }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    /**
     * API call to get devices.
     * @param pageNo current page number
     */
    getList(pageNo) {
        if (pageNo >= 0 && pageNo <= this.state.totalPages) {
            if(this.state.type == masterType.camera){
                this.setState({pageNo},()=>{
                  this.fetchCameraData();
                })
            }else if (this.state.type == masterType.serviceCategory){
              if(this.isServiceCategory()){
                this.setState({pageNo},()=>{
                  this.fetchBusClassificationData();
                })
              }else{
                this.setState({pageNo},()=>{
                  this.fetchServiceProviderData();
                })
              }
            }else{
               if(this.state.type == masterType.language){
                this.setState({pageNo},()=>{
                  this.filterLanguageMaster()
                })
               }else{
                 this.fetchMasterData(pageNo,this.state.selectedCountry);
               }
            }
        }
    }

    handleToggleChange(column,status, updateData) {
        if(column==='status'){
            this.setState({
                showUpdateConfirmationModal: true,
                UpdateMasterId: updateData.id,
                updatedStatus: status
            })
        }else{
            this.setState({
                showUpdateliveTrackingModal: true,
                UpdateMasterId: updateData.id,
                updatedStatus: status
            })
        }
      
    }

    /**
    * Used to show master data in row in List view
    * @param item  Object of item to display
    */
    displayList(item, templateStrings) {

      function generateUniqueString() {
        const array = new Uint8Array(16);
        crypto.getRandomValues(array);
        return Array.from(array, value => ('0' + value.toString(16)).slice(-2)).join('');
      }

      function getDisplayValueForCategoryProvider (row,column){
        if (row[column] && row[column].length) {
          const agencyName = row[column].map((el)=>el['agencyName']);
          if (row[column].length <= 2) {
            return agencyName.join();
          } else {
            return agencyName.slice(0, 2).join() + '...';
          }
        } else {
          return 'NA';
        }
      }

     function getDispalyForLanguageColumn (row,column)  {
        if(row[column] && row[column].length){
          const languages = row[column].map((el)=>el.language);
          if(languages.length<=2){
            return languages.join();
          }
          return languages.join() + '...';
        }
        return 'NA';
      }
      function getVRowValue  (column, row) {
        if (column === 'isActive') {
          return row[column] ? 'Active' : 'InActive';
        } else if (['agencyCategoryDto','agencyProviderDto'].includes(column)) {
          return getDisplayValueForCategoryProvider(row,column);
        }else if(column == 'languages'){
          return getDispalyForLanguageColumn(row,column);
        } else {
          return row[column] ? row[column] : 'NA';
        }
      }
      const viewIconsNotEnableFor = [masterType.serviceCategory,masterType.language];
        return (
          <tr key={generateUniqueString()}>
            {Object.keys(templateStrings).map((column, index) => {
              return (
                <td className="master-table" key={generateUniqueString()}>
                  {column === "status" || column === "liveTracking" ? (
                    <div>
                      {this.state.canWrite ? (
                        <ToggleView
                          status={item[column]}
                          masterData={item}
                          onStatusChange={this.handleToggleChange.bind(
                            this,
                            column
                          )}
                        ></ToggleView>
                      ) : item[column] ? (
                        "Active"
                      ) : (
                        "InActive "
                      )}
                    </div>
                  ) : column === "city" ? (
                    this.joinCities(item[column])
                  ) : column === "agency" ? (
                    item[column]["name"]
                  ) : column === "action" ? (
                    <div className="action-column">
                      {this.state.canWrite && !viewIconsNotEnableFor.includes(this.state.type) && (
                        <>
                        <span className="table-icons edit-icon">
                          <a onClick={this.open.bind(this, item)}>
                            <span className="edit-view-icon" />
                            <span className="protocol-list">edit</span>
                          </a>
                        </span>
                      <span className="table-icons view-icon">
                        <a
                          onClick={() =>
                            this.setState({
                              showInfo: true,
                              updateSelection: item,
                            })
                          }
                        >
                          <i
                            className="fa fa-eye protocol"
                            aria-hidden="true"
                          />
                          <span className="protocol-list">View Details</span>
                        </a>
                      </span> 
                      </>
                      )}
                      <>
                      {viewIconsNotEnableFor.includes(this.state.type) && <span className="table-icons edit-icon" 
                          style={{cursor:'pointer'}}
                          onClick={() =>
                            this.setState({ showInfo: true, updateSelection: item, })
                          }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                          <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                          <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                        </svg>
                      </span>
                      }
                      {
                        this.state.type == masterType.camera && (
                          <span className="table-icons view-icon">
                            <a onClick={() => {this.navigateToAudit(item)}}>
                              <i className="fa fa-history protocol" aria-hidden="true" />
                              <span className="protocol-list">view logs</span>
                            </a>
                          </span>
                        )

                      }
                      </>
                    </div>
                  ) : column == "currencySymbol" ? (
                    <span style={{ fontFamily: "DejaVu Sans" }}>
                      {item["currencySymbol"]}
                    </span>
                  ) : column == "operatorVerified" ? (
                    <span
                      className={item[column] ? "green-class" : "red-class"}
                    >
                      <a>
                        <i className="fa fa-circle" aria-hidden="true"></i>
                      </a>
                    </span>
                  ) : column == "profilePercent" ? (
                    item[column].toString() + "%"
                  ) : column == 'updatedAt' ? (
                    moment(
                        new Date(
                          EpochtoEpochTZ(
                            parseInt(item.updatedAt),
                            localStorage.getItem("timeZone")
                          )
                        )
                      ).format('DD/MM/YYYY')
                  ) : column == 'createdAt' ? (
                    moment(
                        new Date(
                          EpochtoEpochTZ(
                            parseInt(item.createdAt),
                            localStorage.getItem("timeZone")
                          )
                        )
                      ).format('DD/MM/YYYY')
                  ) : getVRowValue(column, item)}
                </td>
              );
            })}
          </tr>
        );
    }


    joinCities(city) {
        if (Array.isArray(city)) {
            return Array.prototype.map.call(city, s => s.name).join(', ');

        } else {
            return city['name']
        }

    }

    synLanguageTableAfterIndividualLangRemove(){
      this.setState({
        pageNo: 0
    }, this.fetchMasterData(this.state.pageNo,this.state.selectedCountry));
    }

    navigateToAudit(clickedRow){
      this.props.history.push(`/vehicleMaster/camera/details/${clickedRow.cameraId}`);
    }
}



export default connect(null, defaultMapDispatchToProps)(MasterList);

import { getAPIPromise,getValidToken } from "./APIutils";
import { BASE_URL, GET_CITIES_MASTER, GET_AGENCY_MASTER, GET_DEPOT_MASTER, GET_OPERATOR_MASTER, GET_VEHICLE_OPERATION_TYPE ,
    GET_VEHICLE_SERVICE_TYPE,GET_VEHICLE_APPLICATIONS,GET_VEHICLE_TYPE,GET_ID_TYPE, GET_PROTOCOL_TYPE, APPROVE_ID, APPROVE_PAN, APPROVE_BANK, GET_COUNTRIES_MASTER,GET_CAMERA_MASTER,GET_LANGUAGE,BASE_GPS_MATRIX_STAG_URL} from "../Constant";
import { masterType } from '../views/Dashboard/vehicleMaster/utils'
import FileDownLoader from "./fileDownloader";

export function fetchVehicleMaster(masterDataType, parentId, pageNo, pageSIze = 10,key=null,status,countryId,countryName) {
    let url = setMasterTypeUrl(masterDataType);
    url = `${url}?pageNo=${pageNo}&pageSize=${pageSIze}`;

    if (parentId) {
        if (parentId.cityId) {
            url = `${url}&cityId=${parentId.cityId}`;
        } if(parentId.agencyId){ url = `${url}&agencyId=${parentId.agencyId}`; }
    }
    if(key && masterDataType){
        if( masterDataType == masterType.country){
            url = `${url}&searchKey=${key}`;
        }
        else{
            url = `${url}&key=${key}`;
        }
    }
    if(status!=undefined){
        url = `${url}&status=${status}`;
        if(masterDataType == "operator"){
            url = `${url}&verified=true`; 
        }
    }
    if(masterDataType == "operator" && status == undefined){
        url = `${url}&verified=all`; 
    }
    if(masterDataType === 'city' && countryId && countryName){
        url = `${url}&country=${countryName}&countryId=${countryId}`;
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchCountryData(){
    let  url = `${BASE_URL}api/master/country?pageNo=0&pageSize=1000`;
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchVehicleMetaData(masterDataType) {
    let url;
    switch (masterDataType) {
        case 'vehicleType': {
            url = `${BASE_URL}${GET_VEHICLE_TYPE}`;
            break;
        }
        case 'operationType': {
            url = `${BASE_URL}${GET_VEHICLE_OPERATION_TYPE}`;
            break;
        }
        case 'serviceType': {
            url = `${BASE_URL}${GET_VEHICLE_SERVICE_TYPE}`;
            break;
        }
        case 'applications': {
            url = `${BASE_URL}${GET_VEHICLE_APPLICATIONS}`;
            break;
        }
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function createMasterData(masterDataType, state,updatedData) {
    let data = state
    let url = setMasterTypeUrl(masterDataType)
    return getAPIPromise(url, {
        method: updatedData ? 'PUT' : 'POST',
        body: masterDataType === masterType.operator || masterDataType === masterType.country ? data : JSON.stringify(data),
        ...(!(masterDataType === masterType.operator || masterDataType === masterType.country ) ? {headers: {
            'Content-Type': 'application/json'
        }} : {})
    });
}

export function upadteMasterDataStatus(masterDataType, state) {
    let url = setMasterTypeUrl(masterDataType);
    url=`${url}/status`
    url = `${url}?status=${state.status}`
    url = `${url}&${masterIdMappaing[masterDataType]}=${state.id}`
    return getAPIPromise(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export function upadteMasterDataLiveTracking(masterDataType, state) {
    let url = setMasterTypeUrl(masterDataType);
    url=`${url}/status/liveTracking`
    url = `${url}?liveTracking=${state.status}`
    url = `${url}&${masterIdMappaing[masterDataType]}=${state.id}`
    return getAPIPromise(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

function setMasterTypeUrl(masterDataType) {
    let url;
    switch (masterDataType) {
        case masterType.city: {
            url = `${BASE_URL}${GET_CITIES_MASTER}`;
            break;
        }
        case masterType.country: {
            url = `${BASE_URL}${GET_COUNTRIES_MASTER}`;
            break;
        }
        case masterType.agency: {
            url = `${BASE_URL}${GET_AGENCY_MASTER}`;
            break;
        }
        case masterType.operator: {
            url = `${BASE_URL}${GET_OPERATOR_MASTER}`;
            break;
        }
        case masterType.depot: {
            url = `${BASE_URL}${GET_DEPOT_MASTER}`;
            break;
        }
        case masterType.camera: {
            url = `${BASE_URL}${GET_CAMERA_MASTER}`;
            break;
        }
        case masterType.language:{
            url = `${BASE_URL}${GET_LANGUAGE}`;
            break;
        }
    }
    return url;
}

export function fetchImgaes(id, docType) {
    let token = localStorage.getItem("token");
    let url = `${BASE_URL}api/master/${docType !== "country"? 'operator' : 'country'}/downloadFile`;
    if (docType !== "country") {
      url = `${url}?operatorId=${id}&docType=${docType}`;
    } else {
      url = `${url}?countryName=${id}`;
    }
    return fetch(url, {
      method: "get",
      headers: {
        Authorization: "Bearer " + token,
        responseType: "blob",
      },
    });
  }  

export function fetchIdType() {
    let  url = `${BASE_URL}${GET_ID_TYPE}`;
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchProtocolType() {
    let  url = `${BASE_URL}${GET_PROTOCOL_TYPE}`;
    return getAPIPromise(url, {
        method: 'get'
    });
}

const masterIdMappaing = {
    city: 'cityId',
    agency: 'agencyId',
    operator: 'operatorId',
    depot: 'depotId'
};

export function verifyId(operatorId,status){
    let  url = `${BASE_URL}${APPROVE_ID}?operatorId=${operatorId}&status=${status}`;
    return getAPIPromise(url, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
export function verifyPAN(operatorId,status){
    let  url = `${BASE_URL}${APPROVE_PAN}?operatorId=${operatorId}&status=${status}`;
    return getAPIPromise(url, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
export function verifyBank(operatorId,status){
    let  url = `${BASE_URL}${APPROVE_BANK}?operatorId=${operatorId}&status=${status}`;
    return getAPIPromise(url, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export function fetchCameraData(searchKey,pageNo){
    let  url = `${BASE_URL}api/master/camera?pageNo=${pageNo}&pageSize=10`;
    if(searchKey){
        url = `${BASE_URL}api/master/camera?searchKey=${searchKey}&sortKey=updatedAt&sortOrder=ASC&pageNo=${pageNo}&pageSize=10`;
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function upsertCameraMasterData(masterDataType, state,updatedData) {
    let data = state
    let url = setMasterTypeUrl(masterDataType);
    if(updatedData && updatedData.cameraId){
        url = `${url}?cameraId=${updatedData.cameraId}`
    }else{
        url = `${url}`
    }
    return getAPIPromise(url, {
        method: updatedData ? 'PUT' : 'POST',
        body: masterDataType === masterType.operator || masterDataType === masterType.country ? data : JSON.stringify(data),
        ...(!(masterDataType === masterType.operator || masterDataType === masterType.country ) ? {headers: {
            'Content-Type': 'application/json'
        }} : {})
    });
}

export function downLoadCameraData(exportKey, data) {
    let url = `${BASE_URL}api/master/camera/export`;
    if (exportKey == "format") {
        url = `${url}/format`;
    }


    return getValidToken().then((token) => {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: { Authorization: 'Bearer ' + token }
        });
    })

  
}

export function deleteCameraMasterData(cameraId) {
    const url = `${BASE_URL}api/master/camera?cameraId=${cameraId}`
    return getAPIPromise(url, {
        method: 'delete'
    });
}
export function fetchCameraServiceNames(){
    let url=`${BASE_URL}api/master/camera/camera-service-names`
    return getAPIPromise(url,{
        method:'get'
    })
}
export function fetchCameraPositionData(){
    let  url = `${BASE_URL}api/master/camera/cameraPositions`;
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function fetchBusClassification({agencyName,cityId}){
    const url = `${BASE_URL}api/serviceCategory/agency/filter?agencyName=${agencyName}&cityId=${cityId}`;
    return getAPIPromise(url,{
        method:'get'
    })
}

export function fetchAllBusClassification({pageNo}){
    const url = `${BASE_URL}api/serviceCategory/all?pageNo=${pageNo}&pageSize=10`;
    return getAPIPromise(url,{
        method:'get'
    })
}
export function upsertServiceCategory(data,createMode=false){
    let url = `${BASE_URL}api/serviceCategory`
    return getAPIPromise(url, {
        method:!createMode ? 'POST' : 'PUT',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        body: JSON.stringify(data),
    });
}
export function fetchTags(data){
    const url = `${BASE_URL}api/tags/region/${data.value}`
    return getAPIPromise(url,{
        method:'get'
    })
}

export function getCityData(){
    const url = `${BASE_URL}api/master/city?pageNo=0&pageSize=1000`
    return getAPIPromise(url,{
        method:'get'
    })
}

export function fetchCameraBrands(){
    const url = `${BASE_URL}api/master/camera/cameraBrands`
    return getAPIPromise(url,{
        method:'get'
    })
}

export function fetchCameraTypes(){
    const url = `${BASE_URL}api/master/camera/cameraTypes`
    return getAPIPromise(url,{
        method:'get'
    })
}


export function fetchServiceProvider({pageNo}){
    const url = `${BASE_URL}api/serviceProvider/all?pageNo=${pageNo}&pageSize=10`;
    return getAPIPromise(url,{
        method:'get'
    })
}

export function upsertServiceProvider(data,createMode=false){
    let url = `${BASE_URL}api/serviceProvider`;
    return getAPIPromise(url, {
        method:!createMode ? 'POST' : 'PUT',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        body: JSON.stringify(data),
    });
}

export function fetchServiceProviderWithCity({agencyName,cityId}){
    const url = `${BASE_URL}api/serviceProvider/agency/filter?agencyName=${agencyName}&cityId=${cityId}`;
    return getAPIPromise(url,{
        method:'get'
    })
}


export function editDisplayName(data,activeTab){
    let url = `${BASE_URL}api/${activeTab == 'category'?'serviceCategory':'serviceProvider'}/displayName`;
    return getAPIPromise(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export function deleteCategory(data,isMultiDelete,activeTab) {
    const url = `${BASE_URL}api/${activeTab == 'category'?'serviceCategory':'serviceProvider'}?delete=${isMultiDelete}`;
    return getAPIPromise(url, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export function getAllLanguages(){
    const url = `${BASE_URL}api/master/languages`;
    return getAPIPromise(url,{
        method: 'GET'
    })
}

export function addLanguagesToCity(data,mode){
    const url = `${BASE_URL}api/master/languages/city`;
    return getAPIPromise(url,{
        method: mode ? 'PUT':'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export function deleteLanguageWithCity(cityId,languageIds) {
    const url = `${BASE_URL}api/master/languages/city?cityId=${cityId}&languageIds=${languageIds}`
    return getAPIPromise(url, {
        method: 'delete'
    });
}

export function filterLanguageMasterReport(data){
    const {cityId,countryId,languageId,pageNo} = data;
    let url = `${BASE_URL}api/master/languages/filter?pageNo=${pageNo}&pageSize=10`;
    if(cityId){
        url = `${url}&cityId=${cityId}`;
    }
    if(countryId){
        url = `${url}&countryId=${countryId}`;
    }
    if(languageId){
        url = `${url}&languageId=${languageId}`;
    }
    return getAPIPromise(url, {
        method: 'GET',
    });
}

export function getPanicReport({selectedRegion,startDate,endDate,pageNo,searchString}){
    let  url = `${BASE_GPS_MATRIX_STAG_URL}api/alert/v1?pageNo=${pageNo}&pageSize=10&fromDate=${startDate}&toDate=${endDate}&alertType=PANIC_ALERT&regionId=${selectedRegion}`;  
    if(searchString){
        url = `${url}&searchKey=${searchString}`;
    }
    
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function getPanicReportDetail({startDate,endDate,pageNo,type}){
    const  url = `${BASE_GPS_MATRIX_STAG_URL}api/alert/v1/device/${type}?pageNo=${pageNo}&pageSize=10&fromDate=${startDate}&toDate=${endDate}&alertType=PANIC_ALERT`; 
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function getPanicReportNotification(){
    return getAPIPromise(`${BASE_GPS_MATRIX_STAG_URL}api/alert/v1/panic/notification?previousAlertTimestamp=${localStorage.getItem("previousAlertTimestamp")}`, {
        method: 'get'
    });
}

export function getRegionsForPanicReport(){
    return getAPIPromise(`${BASE_URL}api/region`, {
        method: 'get'
    });
}

export function fetchFuelTypes(){
    return getAPIPromise(`${BASE_URL}api/master/vehicle/fuel-type/v1`, {
        method: 'get'
    });
}
import {routes} from "../../routing/routes";
export const Authorities = {
    Owner: 'Owner',
    Admin: 'Admin',
    Manager: 'Manager',
    Operation: 'Operation',
    Partner: 'Partner',
    Developer: 'Developer'
};

export const OPERATIONS = {
    READ: Symbol('READ'),
    WRITE: Symbol('WRITE'),
};

export const ACCESS_RIGHTS_LIMIT = {
    [routes.HOME]: {
        [OPERATIONS.READ]: [Authorities.Owner, Authorities.Admin, Authorities.Manager]
    },
    [routes.VEHICLE]: {
        [OPERATIONS.READ]: [Authorities.Owner, Authorities.Admin, Authorities.Manager, Authorities.Operation],
        [OPERATIONS.WRITE]: [Authorities.Owner, Authorities.Admin, Authorities.Manager],
    },
    [routes.BSD]: {
        [OPERATIONS.READ]: [Authorities.Admin],
        [OPERATIONS.WRITE]: [Authorities.Admin],
    },
    [routes.MASTER]: {
        [OPERATIONS.READ]: [Authorities.Owner, Authorities.Admin],
        [OPERATIONS.WRITE]: [Authorities.Owner, Authorities.Admin],
    },
    [routes.VIEWMASTER]: {
        [OPERATIONS.READ]: [Authorities.Manager],
        [OPERATIONS.WRITE]: [Authorities.Manager]
    },
    [routes.DEVICE]: {
        [OPERATIONS.READ]: [Authorities.Owner, Authorities.Admin, Authorities.Manager, Authorities.Operation],
        [OPERATIONS.WRITE]: [Authorities.Owner, Authorities.Admin, Authorities.Manager],
    },
    [routes.REGION]: {
        [OPERATIONS.READ]: [Authorities.Owner, Authorities.Admin, Authorities.Manager],
        [OPERATIONS.WRITE]: [Authorities.Owner, Authorities.Admin],
    },
    [routes.PROTOCOL]: {
        [OPERATIONS.READ]: [Authorities.Owner, Authorities.Admin, Authorities.Manager],
        [OPERATIONS.WRITE]: [Authorities.Owner, Authorities.Admin],
    },
    [routes.TRIP]: {
        [OPERATIONS.READ]: [Authorities.Owner, Authorities.Admin],
        [OPERATIONS.WRITE]: [Authorities.Owner, Authorities.Admin],
    },
    [routes.CRON]: {
        [OPERATIONS.READ]: [Authorities.Owner, Authorities.Admin],
        [OPERATIONS.WRITE]: [Authorities.Owner, Authorities.Admin],
    }

};
